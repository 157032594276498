<button
  (click)="onClick()"
  type="button"
  [class.invisible]="!showHandToolButton"
  [class.toggled]="isSelected"
  id="primaryCursorHandTool"
  class="toolbarButton hiddenXXLView"
  title="Enable hand tool"
  data-l10n-id="cursor_hand_tool">
  <svg style="width:22px;height:22px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z" />
  </svg>
  <span data-l10n-id="cursor_hand_tool_label">Hand Tool</span>
</button>

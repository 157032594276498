<input
  type="checkbox"
  id="findFuzzy"
  class="toolbarField"
/>
<label
  for="findFuzzy"
  class="toolbarLabel"
  data-l10n-id="find_fuzzy">
    Fuzzy search
</label>

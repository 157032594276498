<input
  type="checkbox"
  id="findMatchCase"
  class="toolbarField"
/>
<label
  for="findMatchCase"
  class="toolbarLabel"
  data-l10n-id="find_match_case_label">
    Match case
</label>

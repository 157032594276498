<input [class.hidden]="!pdfFindbarService.individualWordsMode"
  autocomplete="search-input-field"
  id="findInput"
  class="toolbarField"
  title="Find"
  [placeholder]="'Find in document…'"
  data-l10n-id="find_input"
  name="search-input-field"
/>
<textarea
  [class.hidden]="pdfFindbarService.individualWordsMode"
  id="findInputMultiline"
  type="checkbox"
  class="toolbarField"
  placeholder="Multiple search terms. Each line is a search term."
  data-l10n-id="find_input_line_by_line"
  lines="3"
></textarea>

<input
  type="checkbox"
  id="findCurrentPage"
  class="toolbarField"
/>
<label
  for="findCurrentPage"
  class="toolbarLabel"
  data-l10n-id="find_current_page">
  Current page only
</label>

<button type="button"
  class="toolbarButton hiddenLargeView"
  title="Last page"
  id="primaryLastPage"
  data-l10n-id="last_page"
  (click)="lastPage()"
  [disabled]="disableLastPage"
  #button
  >
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z" />
  </svg>
  <span data-l10n-id="last_page_label">Last page</span>
</button>

<input
  type="checkbox"
  id="findHighlightAll"
  class="toolbarField"
/>
<label
  for="findHighlightAll"
  class="toolbarLabel"
  data-l10n-id="find_highlight">
  Highlight all
</label>

<button type="button"
  id="zoomIn"
  class="toolbarButton zoomIn"
  title="Zoom In"
  data-l10n-id="zoom_in">
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </svg>
  <span data-l10n-id="zoom_in_label">Zoom In</span>
</button>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-next-page',
  templateUrl: './pdf-next-page.component.html',
  styleUrls: ['./pdf-next-page.component.css']
})
export class PdfNextPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

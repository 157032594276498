<input
  type="checkbox"
  id="findIgnoreAccents"
  class="toolbarField"
/>
<label
  for="findIgnoreAccents"
  class="toolbarLabel"
  data-l10n-id="find_ignore_accents">
    Ignore accents and diacritics
</label>

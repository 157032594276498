<button type="button"
  class="toolbarButton"
  title="Next Page"
  id="next"
  data-l10n-id="next"
  >
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
  </svg>
  <span data-l10n-id="next_label">Next</span>
</button>

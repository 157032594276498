<input
  type="checkbox"
  id="findEntireWord"
  class="toolbarField"
/>
<label
  for="findEntireWord"
  class="toolbarLabel"
  data-l10n-id="find_entire_word_label">
  Whole words
</label>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-find-ignore-accents',
  templateUrl: './pdf-find-ignore-accents.component.html',
  styleUrls: ['./pdf-find-ignore-accents.component.css']
})
export class PdfFindIgnoreAccentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

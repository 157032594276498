<link *ngIf="useBrowserLocale" rel="resource" type="application/l10n" [attr.xhref]="localeFolderPath+'/locale.properties'" origin="ngx-extended-pdf-viewer" />
<pdf-dark-theme *ngIf="theme==='dark'"></pdf-dark-theme>
<pdf-light-theme *ngIf="theme==='light'"></pdf-light-theme>

<pdf-dynamic-css [zoom]="mobileFriendlyZoomScale" [width]="toolbarWidthInPixels"></pdf-dynamic-css>
<div class="zoom" [style.height]="height">
  <div class="html">
    <div id="mainContainer">
      <pdf-dummy-components></pdf-dummy-components>
    </div>
  </div>
</div>

<ng-template #defaultFreeFloatingBar>

</ng-template>

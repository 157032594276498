<button type="button"
    [class.invisible]="!showPrintButton"
    id="print"
    class="toolbarButton hiddenSmallView"
    title="Print"
    data-l10n-id="print"
    >
    <svg style="width:22px;height:22px" viewBox="0 0 24 24">
        <path fill="currentColor" d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" />
    </svg>
    <span data-l10n-id="print_label">Print</span>
</button>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-document-properties-overlay',
  templateUrl: './pdf-document-properties-overlay.component.html',
  styleUrls: ['./pdf-document-properties-overlay.component.css']
})
export class PdfDocumentPropertiesOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

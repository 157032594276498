<button type="button"
  id="findPrevious"
  class="toolbarButton"
  title="Find the previous occurrence of the phrase"
  data-l10n-id="find_previous"
  >
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
  </svg>
  <span data-l10n-id="find_previous_label">Previous</span>
</button>

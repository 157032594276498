<div>
  <div class="dialog">
    <div class="row">
      <span data-l10n-id="document_properties_file_name"
        >File name:</span
      >
      <p id="fileNameField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_file_size"
        >File size:</span
      >
      <p id="fileSizeField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_title">Title:</span>
      <p id="titleField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_author">Author:</span>
      <p id="authorField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_subject">Subject:</span>
      <p id="subjectField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_keywords"
        >Keywords:</span
      >
      <p id="keywordsField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_creation_date"
        >Creation Date:</span
      >
      <p id="creationDateField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_modification_date"
        >Modification Date:</span
      >
      <p id="modificationDateField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_creator">Creator:</span>
      <p id="creatorField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_producer"
        >PDF Producer:</span
      >
      <p id="producerField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_version"
        >PDF Version:</span
      >
      <p id="versionField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_page_count"
        >Page Count:</span
      >
      <p id="pageCountField">-</p>
    </div>
    <div class="row">
      <span data-l10n-id="document_properties_page_size"
        >Page Size:</span
      >
      <p id="pageSizeField">-</p>
    </div>
    <div class="separator"></div>
    <div class="row">
      <span data-l10n-id="document_properties_linearized"
        >Fast Web View:</span
      >
      <p id="linearizedField">-</p>
    </div>
    <div class="buttonRow">
      <button type="button" id="documentPropertiesClose" class="overlayButton">
        <span data-l10n-id="document_properties_close">Close</span>
      </button>
    </div>
  </div>
</div>

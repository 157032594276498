<button
  type="button"
  [class.invisible]="!showSidebarButton"
  id="sidebarToggle"
  title="Toggle Sidebar"
  class="toolbarButton"
  data-l10n-id="toggle_sidebar"
>
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3,9H17V7H3V9M3,13H17V11H3V13M3,17H17V15H3V17M19,17H21V15H19V17M19,7V9H21V7H19M19,13H21V11H19V13Z" />
  </svg>
  <span data-l10n-id="toggle_sidebar_label">Toggle Sidebar</span>
</button>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-previous-page',
  templateUrl: './pdf-previous-page.component.html',
  styleUrls: ['./pdf-previous-page.component.css']
})
export class PdfPreviousPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

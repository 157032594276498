<button type="button"
  id="findNext"
  class="toolbarButton"
  title="Find the next occurrence of the phrase"
  data-l10n-id="find_next">
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
  </svg>
  <span data-l10n-id="find_next_label">Next</span>
</button>

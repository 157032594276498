<a
  href="#"
  [class.invisible]="!showBookmarkButton"
  id="viewBookmark"
  class="toolbarButton hiddenTinyView"
  title="Current view (copy or open in new window)"
  data-l10n-id="bookmark"
>
  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M17,18L12,15.82L7,18V5H17M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z" />
  </svg>
  <span data-l10n-id="bookmark_label">Current View</span>
</a>

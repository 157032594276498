<input
  type="checkbox"
  id="findMultipleSearchTexts"
  class="toolbarField"
  [(ngModel)]="pdfFindbarService.multipleSearchTexts"
/>

<label
  for="findMultipleSearchTexts"
  class="toolbarLabel"
  data-l10n-id="find_multiple_texts_label">
  multiple search texts
</label>

<input [class.hidden]="!pdfFindbarService.multipleSearchTexts"
  type="checkbox"
  id="individualWordsMode"
  class="toolbarField"
  [(ngModel)]="pdfFindbarService.individualWordsMode"
/>

<label [class.hidden]="!pdfFindbarService.multipleSearchTexts"
  for="individualWordsMode"
  id="individualWordsModeLabel"
  class="toolbarLabel"
  data-l10n-id="find_individual_words_label">
  separated by word boundaries
</label>
